import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class Globals {
  static _imagePrefixUrl: string;
  static _bingoRoomID: number;
  static _APIURl: string;
  static _signalR: string;
  static url: string;
  static _API2URL: string;
  static altenarUrl: string;

  goto = ['vá para', 'go to', 'ir a'];

  get imagePrefixUrl() {
    return Globals._imagePrefixUrl;
  }

  get url() {
    return Globals.url;
  }

  get bingoRoomID() {
    return Globals._bingoRoomID;
  }

  get _API2URL() {
    return Globals._API2URL;
  }
}
